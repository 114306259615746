<template>
  <v-main>
    <Banner/>
    <v-container fluid>
      <p class="text-center pa-3">Passed down from generation to generation, this sacred recipe was entrusted to us by our beloved grandmother. Now, we're Pancit-ing out this mouth-watering kuih to fellow Pancit enthusiasts like you!</p>
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-slide-group v-model="tab" mandatory>
            <v-slide-item
              class="ma-2 ma-md-4 ma-lg-8 rounded-lg" 
              v-for="menu in menuTypes" 
              :key="menu.name" 
              v-slot="{ active, toggle }"
            >
              <v-img 
                contain 
                :src="menu.link" 
                aspect-ratio="1" 
                @click="toggle" 
                :class="active ? 'elevation-0' : 'elevation-14'"
                :style="[active ? {} : {'cursor': 'pointer'}]"
              ></v-img>
            </v-slide-item>
          </v-slide-group>
        </v-col>
        <v-col
          cols="6"
          md="4"
          v-for="(menu, index) in getMenus"
          :key="menu.id"
          class="px-md-7"
          :class="index % 2 == 0 ? 'pr-1' : 'pl-1'"
        >
          <v-card>
            <v-card-title 
              :class="$vuetify.breakpoint.xs?'text-caption':''"
              class="px-2 px-md-4"
            >
              {{ menu.name }}
            </v-card-title>
            <v-img :src="menu.image_url" aspect-ratio="1"></v-img>
            <v-expansion-panels v-if="$vuetify.breakpoint.xs" flat>
              <v-expansion-panel>
                <v-expansion-panel-header hide-actions>
                  <template v-slot:default="{ open }">
                    <v-row align="center">
                      <v-icon :color="getColor(menu.menu_type)" class="mr-5">
                        <template v-if="open">mdi-chevron-up</template>
                        <template v-else>mdi-chevron-down</template>
                      </v-icon>
                      <span v-if="!open">{{ menu.description.substring(0, 10) + '..' }}</span>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>{{ menu.description }}</p>
                  <em v-if="menu.menu_type != 'Mixed'">{{ subheadings[menu.code.charAt(1)] }}</em>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-card-text v-else class="card-text-height">
              <p>Code: {{ menu.code }}</p>
              <p>{{ menu.description }}</p>
              <em v-if="menu.menu_type != 'Mixed'">{{ subheadings[menu.code.charAt(1)] }}</em>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="py-0">
              <v-btn :color="getColor(menu.menu_type)" @click="decrement(menu.id)" :disabled="menu.qty == 1" icon><v-icon>mdi-minus-circle-outline</v-icon></v-btn>
              <span>{{ menu.qty }}</span>
              <v-btn :color="getColor(menu.menu_type)" @click="increment(menu.id)" class="ma-0" icon><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
              <v-spacer></v-spacer>
              <span :class="`${getColor(menu.menu_type)}--text`">$ {{ parseFloat(menu.price).toFixed(2) }}</span>
            </v-card-actions>
            <v-divider></v-divider>
            <v-card-actions>
              <v-row align="center">
                <v-col cols="12" sm="5" md="4" lg="5">
                  <span class="grey--text">Subtotal: ${{ parseFloat(menu.price * menu.qty).toFixed(2) }}</span>
                </v-col>
                <v-col cols="12" sm="7" md="8" lg="7" :align="$vuetify.breakpoint.xs?'center':'end'">
                  <v-btn
                    depressed
                    :color="getColor(menu.menu_type)"
                    class="text-subtitle-2 white--text px-4"
                    @click="addToCart(menu.id)"
                    :block="$vuetify.breakpoint.xs"
                  >
                    <v-icon class="pr-1">mdi-plus</v-icon>
                    Add to Cart
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import apiService from '@/services/api.service.js'
import Banner from '@/components/Banner.vue'

export default {
  components: {
    Banner
  },
  data () {
    return {
      tab: 0,
      menus: [],
      menuTypes: [ 
        { name: 'Pandan', link: 'https://pancit-production.sgp1.cdn.digitaloceanspaces.com/Tabs/pandan.jpg'},
        { name: 'Taro', link: 'https://pancit-production.sgp1.cdn.digitaloceanspaces.com/Tabs/taro.jpg'},
        { name: 'Caramel', link: 'https://pancit-production.sgp1.cdn.digitaloceanspaces.com/Tabs/caramel.jpg'},
        { name: 'Mixed', link: 'https://pancit-production.sgp1.cdn.digitaloceanspaces.com/Tabs/mixed.jpg'},
      ],
      subheadings: {
        M: 'Good for sharing with your loved ones!',
        L: 'Best choice for any gathering events.',
        P: 'Just enough to satisfy your Pancit cravings.'
      }
    }
  },
  methods: {
    async fetchMenu() {
      try {
        const res = await apiService.get('/api/v1/menus')
        const response = res.data
        this.menus = response.data.map(menu => ({ ...menu, qty: 1 }))
      }
      catch(error) {
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue retrieving the menu as this moment. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    decrement(id) {
      this.menus.find(menu => menu.id == id).qty--
    },
    increment(id) {
      this.menus.find(menu => menu.id == id).qty++
    },
    addToCart(id) {
      let menu = this.menus.find(menu => menu.id  == id)
      menu.amount = menu.price * menu.qty
      this.menus = this.menus.map(menu => ({ ...menu, qty: 1 }))
      this.$store.dispatch('addToCart', {product: menu})
      this.$store.dispatch('showSnackbar', 
          { 
            content: `Successfully added ${menu.name} to cart!`, 
            status: 'success' 
          })
    },
    getColor(menuType) {
      switch (menuType) {
        case 'Taro':
          return 'secondary'
        case 'Caramel':
          return 'accent2'
        default:
          return 'primary'
      }
    }
  },
  computed: {
    getMenus() {
      if (this.tab == 0) return this.menus.filter((menu) => menu.menu_type == 'Pandan')
      else if (this.tab == 1) return this.menus.filter((menu) => menu.menu_type == 'Taro')
      else if (this.tab == 2) return this.menus.filter((menu) => menu.menu_type == 'Caramel')
      return this.menus.filter((menu) => menu.menu_type == 'Mixed')
    }
  },
  created () {
    this.fetchMenu()
  }
}
</script>

<style scoped lang="scss">
.card-text-height {
  height: 130px;
}
</style>